export const STATES = {
  alaska: { name: 'Alaska', covered: false },
  alabama: { name: 'Alabama', covered: false },
  arkansas: { name: 'Arkansas', covered: false },
  american_samoa: { name: 'American Samoa', covered: false },
  arizona: { name: 'Arizona', covered: false },
  california: { name: 'California', covered: false },
  colorado: { name: 'Colorado', covered: false },
  connecticut: { name: 'Connecticut', covered: true },
  district_of_columbia: { name: 'District of Columbia', covered: false },
  delaware: { name: 'Delaware', covered: false },
  florida: { name: 'Florida', covered: true },
  georgia: { name: 'Georgia', covered: true },
  guam: { name: 'Guam', covered: false },
  hawaii: { name: 'Hawaii', covered: false },
  iowa: { name: 'Iowa', covered: true },
  idaho: { name: 'Idaho', covered: false },
  illinois: { name: 'Illinois', covered: false },
  indiana: { name: 'Indiana', covered: false },
  kansas: { name: 'Kansas', covered: false },
  kentucky: { name: 'Kentucky', covered: false },
  louisiana: { name: 'Louisiana', covered: false },
  massachusetts: { name: 'Massachusetts', covered: false },
  maryland: { name: 'Maryland', covered: false },
  maine: { name: 'Maine', covered: false },
  michigan: { name: 'Michigan', covered: false },
  minnesota: { name: 'Minnesota', covered: false },
  missouri: { name: 'Missouri', covered: false },
  mississippi: { name: 'Mississippi', covered: true },
  montana: { name: 'Montana', covered: false },
  north_carolina: { name: 'North Carolina', covered: false },
  north_dakota: { name: 'North Dakota', covered: false },
  nebraska: { name: 'Nebraska', covered: false },
  new_hampshire: { name: 'New Hampshire', covered: false },
  new_jersey: { name: 'New Jersey', covered: false },
  new_mexico: { name: 'New Mexico', covered: false },
  nevada: { name: 'Nevada', covered: false },
  new_york: { name: 'New York', covered: false },
  ohio: { name: 'Ohio', covered: true },
  oklahoma: { name: 'Oklahoma', covered: false },
  oregon: { name: 'Oregon', covered: false },
  pennsylvania: { name: 'Pennsylvania', covered: true },
  puerto_rico: { name: 'Puerto Rico', covered: false },
  rhode_island: { name: 'Rhode Island', covered: false },
  south_carolina: { name: 'South Carolina', covered: true },
  south_dakota: { name: 'South Dakota', covered: false },
  tennessee: { name: 'Tennessee', covered: false },
  texas: { name: 'Texas', covered: false },
  utah: { name: 'Utah', covered: false },
  virginia: { name: 'Virginia', covered: false },
  virgin_islands: { name: 'Virgin Islands', covered: false },
  vermont: { name: 'Vermont', covered: false },
  washington: { name: 'Washington', covered: false },
  wisconsin: { name: 'Wisconsin', covered: false },
  west_virginia: { name: 'West Virginia', covered: true },
  wyoming: { name: 'Wyoming', covered: false }
}

<template>
  <div class="profile-box-section">
    <div class="profile-img d-inline-block">
      <img
        v-if="getUser.picture"
        :src="getUser.picture"
        alt="uifaces"
        class="rounded-circle"
      />
      <img
        v-else
        src="/images/uifaces/1.jpg"
        alt="uifaces"
        class="rounded-circle"
      />
      <p class="text-dark">
        <span class="mr-1"></span>{{ getUser.name }}
      </p>
    </div>
    <div class="profile-box d-inline-block text-right">
      
      <a class="font-size-md mr-3 open_selection_btn" href="javascript:void(0);" v-if="selectionExists"><i class="fal fa-check mr-1"></i> My Selection</a>
      <a
        class="font-size-md mr-3"
        data-toggle="collapse"
        href="#collapseExample"
        role="button"
        :aria-expanded="false"
        aria-controls="collapseExample"
        ref="profileBtn"
        ><i class="fal fa-user mr-1"></i> Profile</a>
      <a class="font-size-md" href="javascript:void(0);" @click.prevent="reset" v-if="selectionExists"><i class="far fa-sync-alt"></i></a>
    </div>
    <div class="collapse profile-show" id="collapseExample">
      <div class="card card-body">
        <div class="form-group">
          <label for="full_name" class="text-dark">Status</label>
          <select class="form-control select2" v-model="survey.marital_status">
            <option value="Married">Married</option>
            <option value="Single">Single</option>
          </select>
        </div>
        <div class="form-group">
          <label for="current_age" class="text-dark">Current Age</label>
					<select class="form-control select2" v-model="survey.age">
						<template v-for="age in ageRange">
            	<option :value="age" :key="age">{{age}}</option>
						</template>
          </select>
        </div>
        <div class="form-group">
          <label for="state_name" class="text-dark"
            >State of Primary Residence</label
          >
          <Select2
            v-model="getUser.state"
            selectionCssClass="select2-results__option--selected"
            placeholder="Select State"
            :options="states"
            :settings="{}"
          />
        </div>
        <div class="profile_footer text-center">
          <button
            class="btn btn-primary btn-block"
            :class="{ 'btn-load': isSubmitting }"
            @click="updateProfile"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.profile-show .select2-container {
  width: 100% !important;
}
</style>

<script>
import { STATES } from "@/data/states";

import { range } from 'lodash';
const stateOptions = Object.keys(STATES).map((state_key) => {
  return {
    id: state_key,
    text: STATES[state_key].name,
  };
});
import { mapGetters, mapActions, mapMutations } from "vuex";
import { updateContact, usersCollection } from "@/utils/firebase";

export default {
  name: "ProfileNav",
  data() {
    return {
      prop: null,
      states: stateOptions,
      state: null,
      isLoading: true,
      isSubmitting: false,
			ageRange: range(21, 100)
    };
  },
  computed: {
    ...mapGetters("wizard", {
      survey: "getSurvey",
    }),
    ...mapGetters("calculator", {
      selection: "getSelection",
    }),
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId"
    }),
    selectionExists() {
      return  typeof this.selection == 'object' && Object.keys(this.selection).length;
    }
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapActions("calculator", {
      resetCalculator: "resetCalculator",
    }),
    updateProfile() {

      this.isSubmitting = true;
      let values = {
        marital_status: this.survey.marital_status,
        are_you_retired: this.survey.are_you_retired,
        retirement_age: this.survey.retirement_age,
        age: this.survey.age,
        my_top_retirement_concerns: this.survey.my_top_retirement_concerns,
        industry: this.survey.industry,
        personal_finances_attitude: this.survey.personal_finances_attitude,
      }

      usersCollection.doc(this.getUserId).set({
          age: this.survey.age,
          marital_status: this.survey.marital_status,
          state: this.getUser.state,
          updated: new Date().toISOString()
      }, { merge: true })

      updateContact(values)
        .then((response) => {
          this.isSubmitting = false;
          let elem = this.$refs.profileBtn
          elem.click()
          Fire.$emit("setChartData");
          this.$swal({
            // showClass: {
            //   popup: 'animated fadeInDown faster',
            //   icon: 'animated heartBeat delay-1s'
            // },
            // hideClass: {
            //   popup: 'animated fadeOutUp faster',
            // },
            position: 'bottom-end',
            icon: 'success',
            title: 'Profile updated successfully',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch((error) => {
          let elem = this.$refs.profileBtn
          elem.click()
          console.log(error);
          this.isSubmitting = false;
          alert("Something went wrong!");
        });
    },
    reset() {
      this.$swal({
        title: 'Do you want to delete all data?',
        showCancelButton: true,
        confirmButtonText: `Yes, Delete it`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetCalculator(this.getUserId);
        } else if (result.isDenied) {
          
        }
      })
    }
  },
  mounted() {
    $(".profile-box-section .open_selection_btn").click(() => {
      this.openAgeDataModal();
      $("body").addClass("open_selection");
    });
  }
};
</script>

<style>
</style>
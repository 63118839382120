<template>
  <div class="chart-section">
    
    <div class="chart-inner" v-show="selectionExist">
      <div class="results_inner ">
          <div class="row">
              <div class="col-md-4">
                  <div class="results-box">
                      <h5 class="text-dark">Results</h5>
                  </div>
              </div>
              <div class="col-md-8">
                  <div class="estimated_annuity_income">
                      <div class="row">
                          <div class="col">
                              <div class="estimated_annuity_income_txt" v-if="annuity">
                                  <p>Assets Needed For Estimated Annuity Income:</p>
                                  <h6 v-text="annuity"></h6>
                              </div>
                          </div>
                          <div class="col">
                              <div class="estimated_annuity_income_btn">
                                  <p >Run a New Annuity Income Estimate <span v-if="annuityDate">Good as of <i class="fal fa-calendar-alt"></i> {{ annuityDate }}</span></p>
                                  <button class="btn btn-primary text-white font-size-sm" :class="{ 'btn-load': annuityLoading }" :disabled="annuityLoading" @click.prevent="checkAnnuity()">Calculate</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="list-slider"  v-show="selectionExist">
      <div class="chart_wrap">
        <div class="chart_box" id="chartdiv" ref="chartdiv"></div>
      </div>
    </div>
    
    <div class="no_chart_wrap pt-5" v-show="!selectionExist">
      <h5 class="text-center text-dark pt-3">Selected your Income, Expenses, Goals &amp; Challenges from side menu to find your assets needed For estimated annuity income.</h5>
          <lottie-player
            path="libs/lottiefiles/watchlist.json"
            :speed="1"
            :loop="true"
            :autoPlay="true"
          />
    </div>
    
    <result-modal
      
    ></result-modal>
  </div>
</template>   

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
import { mapGetters, mapActions, mapMutations } from "vuex";
import { CALC_CHART_RESULT_KEY, CALC_ANNUITY_HISTORY } from "@/utils/constants";
import { isEmpty } from "lodash";
import { estimateAnnuityIncome, annuityEstimateHistory } from "@/utils/firebase";
import moment from "moment";
import ResultModal from "./ResultModal";
import CalculatorData from "@/data/calculator";

const defaultModals = {
  Income: {
    category: [],
    total: 0,
  },
  Expenses: {
    category: [],
    total: 0,
  },
  Goals: {
    category: [],
    total: 0,
  },
  Challenges: {
    category: [],
    total: 0,
  },
}

export default {
  name: "Chart",
  components: {
    ResultModal,
  },
  data() {
    return {
      CalculatorData,
      defaultModal: {...defaultModals},
      loading: false,
      annuityLoading: false,
      resultModalLoading: false,
      chart: null,
      filterdList: {},
    };
  },    
  computed: {
    ...mapGetters("wizard", {
      survey: "getSurvey",
    }),
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId",
    }),
    ...mapGetters("calculator", {
      progress: "getProgress",
      selection: "getSelection",
      getChartData: "getChartData",
      getAnnuityHistory: "getAnnuityHistory",
    }),
    annuity() {
      if (Object.keys(this.getAnnuityHistory).length) {
        return `$${this.getAnnuityHistory.total_premium}`;
      } else {
				return '';
			}
    },
    annuityDate() {
      if (this.getAnnuityHistory) {
        if (this.getAnnuityHistory.created_at) {
          var momentDate = new moment(this.getAnnuityHistory.created_at);
          return `${momentDate.format("YYYY-MM-DD | h:mm a")}`;
        }
        return "";
      } else {
        return "";
      }
    },
  selectionExist() {
      return  typeof this.selection == 'object' && Object.keys(this.selection).length;
    }
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapActions("calculator", {
      plannerApi: "plannerApi",
      updateAnnuityHistory: "updateAnnuityHistory"
    }),
    generateChartData(chartBlank) {
      chartBlank;
    },
    ...mapMutations("calculator", [
      "SET_CHART_DATA",
      "SET_ANNUITY_HISTORY",
    ]),
    checkAnnuity() {
      
      this.annuityLoading = true;
      
      this.calculate((res2) => {
        let { needs } = res2;
        console.log('needs');
        console.log(needs);
        // needs = [101190,101190,101190,57326,57326,57326,97379,135146,135146,135146,135146,66216,66216,66216,66216,66216,66216,66216,66216,66216,66216,17272,17272,17272,17272,17272,17272,17272,17272,17272,17272,17272,17272,17272,17272,17272];
        let values = {
          needs: needs,
          age: parseInt(this.getUser.age),
          is_single: this.getUser.marital_status == "Married" ? false : true,
          email: this.getUser.email,
        };
  
        estimateAnnuityIncome(values)
          .then((response) => {
            console.log("estimateAnnuityIncome");
            console.log(response);
            let { data } = response;

            let result = data;
            this.annuityLoading = false;
            result.created_at = moment().valueOf();
            result.needs = needs;
						
            this.updateAnnuityHistory({ uid: this.getUserId, result });
          })
          .catch((error) => {
            console.log("error");
            console.log(error);
            this.annuityLoading = false;
            // alert('Something went wrong!')
          });

      });
    },
    calculate(callback) {
      this.loading = true;

      this.getAgeRangeChartData(this.getUser.age, (ageArray) => {
        console.log('ageArray');
        console.log(JSON.stringify(ageArray));
        this.estimate(ageArray, (result) => {
          console.log('estimate');
          console.log(result);
          let chartData = Object.values(result);
          console.log("chartData");
          console.log(chartData);

          this.getExpenceLine(chartData, (max_expense_line, chartData) => {
            this.setExpenceLine(max_expense_line, chartData, (response) => {
              console.log("response");
              console.log(response);

              this.loading = false;
              let { chartData } = response;

              this.SET_CHART_DATA({ uid: this.getUserId, chartData });
              this.chart.data = chartData;

              callback && callback(response);
            });
          });

        });
      });
    },
    getAgeRangeChartData(currentAge, callback) {
      let age = parseInt(currentAge);
      let max = 100;
      let data = {};

      for (var i = age; i <= max; i++) {
        data[i.toString()] = {
          age: i.toString(),
          income: 0,
          expense: 0,
          income_line: 0,
          expense_line: 0,
          need: 0,
        };

        if (i == max) {
          callback && callback(data);
        }
      }
    },
    estimate(ageArray, callback) {
      let keys = Object.keys(this.selection);
      let expense_line = 0;
      keys.forEach((key, index) => {
        let range = this.selection[key].range;
        let min = range[0];
        let max = range[1];
        let amount = this.selection[key].amount;
        let local_line = 0;
        // console.log("key => " + key);
        // console.log("range => " + range);
        // console.log("min => " + min);
        // console.log("max => " + max);
        console.log("amount => " + amount);

        if (key.startsWith("inc_")) {
          for (let i = min; i <= max; i++) {
            if(ageArray[i]) {
              ageArray[i].income += amount;
              ageArray[i].income_line += amount;
              local_line += amount;
              // console.log(key + "=> age => " + i);
              // console.log(key + "=> income => " + ageArray[i].income);
            }
          }
        } else {
          for (let i = min; i <= max; i++) {
            if(ageArray[i]) {
              let localExp = parseInt(ageArray[i].expense);
              console.log('localExp');
              console.log(typeof localExp);
              console.log(localExp);
              ageArray[i].expense = (localExp + amount);
              local_line = (localExp + amount);
              // console.log(key + "=> age => " + i);
              // console.log(key + "=> expense  => " + ageArray[i].expense);
            }
          }
        }

        if (keys.length - 1 == index) {
          callback && callback(ageArray);
        }
      });
    },
    getExpenceLine(chartData, callback) {
      let max_line = 0;
      chartData.forEach((item, index) => {
        let local_line = item.income + item.expense;
        if (local_line > max_line) {
          max_line = local_line;
        }
        if (chartData.length - 1 == index) {
          callback && callback(max_line, chartData);
        }
      });
    },
    setExpenceLine(max_expense_line, chartData, callback) {
      let needs = [];
      chartData.forEach((item, index) => {
        chartData[index].expense_line = max_expense_line;
        needs.push(item.expense - item.income);
        if (chartData.length - 1 == index) {
          callback &&
            callback({
              chartData,
              needs,
            });
        }
      });
    },
    getModalData(age, callback) {
      let keys = Object.keys(this.selection);
      let result = {
        Income: {
          category: [],
          total: 0,
        },
        Expenses: {
          category: [],
          total: 0,
        },
        Goals: {
          category: [],
          total: 0,
        },
        Challenges: {
          category: [],
          total: 0,
        },
      };
      let filterdList = this.filterdList;
      let totalIncome = 0;
      let totalExpenses = 0; 
      keys.forEach((key, index) => {
        let range = this.selection[key].range;
        let min = range[0];
        let max = range[1];
        let amount = this.selection[key].amount;
        // console.log("key => " + key);
        // console.log("range => " + range);
        // console.log("min => " + min);
        // console.log("max => " + max);
        // console.log("amount => " + amount);

        // console.log(key);
        // console.log(filterdList[key]);
        
        if(age >= min && age <= max) {
          let gparent = filterdList[key].gparent;
          if(gparent == 'Income') {
            totalIncome += amount;
          } else {
            totalExpenses += amount;
          }
          result[filterdList[key].gparent].category.push({
            icon : filterdList[key].icon,
            title : filterdList[key].title,
            amount: amount
          });
          result[filterdList[key].gparent].total += amount
        }

        if (keys.length - 1 == index) {
          console.log(totalExpenses);
          console.log(totalIncome);
          let need = totalIncome - totalExpenses;
          callback && callback(age, need, result);
        }
      });
    },
    openAgeDataModal(ev) {
      
      let itemData = ev.target.dataItem.dataContext;
      let age = parseInt(itemData.age);
      this.resultModalLoading = true;

      this.getModalData(age, (age, need, result) => {
        console.log("age");
        console.log(age);
        console.log('result');
        console.log(result);
        
        this.resultModalLoading = false;

        Fire.$emit("openModalResult", {
          need,
          age,
          result
        });

        $('.results-modal').show();
        $('body').addClass('open_chart_popups');
      });

    },
  },
  mounted() {
    Object.keys(CalculatorData.tabs).forEach((item) => {
      let localTabs = CalculatorData.tabs[item].tabs;
      // console.log(localTabs);
      Object.keys(localTabs).forEach((item2) => {
        // console.log(item2);
        let localTabs2 = localTabs[item2];
        // console.log(localTabs2);
          localTabs2.forEach((item3, index) => {
              this.filterdList[item3.id] = {
                icon: item3.icon,
                title: item3.title,
                parent: item2,
                gparent: item,
              };
          });
      });
    });

    $(".close-box a").click(() => {
      $(".results-modal").hide();
      $("body").removeClass("open_chart_popups");
       Fire.$emit("closeModalResult");

    });

    console.log(moment().valueOf());

    Fire.$on("setChartData", () => {
      this.calculate();
    });

    //load annuity history on load from local data
    annuityEstimateHistory.doc(this.getUserId).get().then(document => {
        console.log('annuityEstimateHistory exists =>' + document.exists);
        console.log(document.data());
        let doc = null;
        if (document && document.exists) {
						doc = document.data();
            console.log(doc.history);
						
            this.SET_ANNUITY_HISTORY(doc.history);
        } else {
					// annuityEstimateHistory.doc(this.getUserId).set({history: {}, created: new Date().toISOString()}, { merge: true });
					// this.SET_ANNUITY_HISTORY(null);
            
        }
    });

    // var annuityHistory = localStorage.getItem(CALC_ANNUITY_HISTORY);
    // if (!isEmpty(annuityHistory)) {
    //   // console.log("annuityHistory on load");
    //   // console.log(JSON.parse(annuityHistory));
    //   this.SET_ANNUITY_HISTORY(JSON.parse(annuityHistory));
    // }

    am4core.ready(() => {
      // Create chart instance
      this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

      //load chart on load from local data
      var chartData = localStorage.getItem(CALC_CHART_RESULT_KEY);
      if (!isEmpty(chartData)) {
        // console.log("chart data on load");
        // console.log(JSON.parse(chartData));
        this.chart.data = JSON.parse(chartData);
      }

      // Create axes
      var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "age";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 1;
      categoryAxis.renderer.labels.template.fontSize = 10;
      categoryAxis.renderer.labels.template.fill = am4core.color("#8CA4B0");
      categoryAxis.tooltip.fontSize = 10;
      categoryAxis.tooltip.strokeWidth = 0;
      categoryAxis.tooltip.stroke = am4core.color("#fff");

      /* Create value axis */
      var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 41;
      valueAxis.renderer.minGridDistance = 15;
      valueAxis.numberFormatter.numberFormat = "$0";
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.fill = am4core.color("#8CA4B0");
      valueAxis.tooltip.disabled = true;

      // var lineSeries2 = this.chart.series.push(new am4charts.LineSeries());
      // lineSeries2.name = "Income Line";
      // lineSeries2.dataFields.valueY = "expense_line";
      // lineSeries2.dataFields.categoryX = "age";
      // lineSeries2.strokeWidth = 0;
      // lineSeries2.hiddenInLegend = true;
      // lineSeries2.fill = am4core.color("#ED5A60");
      // lineSeries2.fillOpacity = 0.06;
      // lineSeries2.tooltip.disabled = true;


			var series1 = this.chart.series.push(new am4charts.ColumnSeries());
			series1.name = "Income";
			series1.dataFields.valueY = "income";
			series1.dataFields.categoryX = "age";
			series1.columns.template.width = am4core.percent(40);
			series1.columns.template.fill = am4core.color("#2680E3"); 
			series1.stroke = am4core.color("#FBDEDF");
			series1.strokeWidth = 0;
			series1.columns.template.column.cornerRadiusTopLeft = 40;
			series1.columns.template.column.cornerRadiusTopRight = 40;
			series1.columns.template.column.cornerRadiusBottomLeft = 40;
			series1.columns.template.column.cornerRadiusBottomRight = 40;
			series1.columns.template.events.on("hit", this.openAgeDataModal);

			var series2 = this.chart.series.push(new am4charts.ColumnSeries());
			series2.name = "Expenses, Goals & Challenges";
			series2.dataFields.valueY = "expense";
			series2.dataFields.categoryX = "age";
			series2.columns.template.width = am4core.percent(40);
			series2.columns.template.fill = am4core.color("#668F80"); 
			series2.stroke = am4core.color("#FBDEDF");
			series2.strokeWidth = 0;
			series2.columns.template.column.cornerRadiusTopLeft = 40;
			series2.columns.template.column.cornerRadiusTopRight = 40;
			series2.columns.template.column.cornerRadiusBottomLeft = 40;
			series2.columns.template.column.cornerRadiusBottomRight = 40;
			series2.columns.template.events.on("hit", this.openAgeDataModal);
			series2.clustered = false;
			series2.toBack();


      var lineSeries = this.chart.series.push(new am4charts.LineSeries());
      lineSeries.name = "Income Line";
      lineSeries.dataFields.valueY = "income_line";
      lineSeries.dataFields.categoryX = "age";
      lineSeries.strokeWidth = 4;
      lineSeries.stroke = am4core.color("#FD7A03");
      lineSeries.hiddenInLegend = true;
      lineSeries.fill = am4core.color("#2680E3");
      lineSeries.fillOpacity = 0.4;

      lineSeries.tooltip.pointerOrientation = "vertical";
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.background.fill = am4core.color("#fff");
      lineSeries.tooltipHTML = `<div class="slider-inner">
                                  <div class="agebox position-relative">
                                      <h6>Age:</h6>
                                      <h4 class="position-absolute right-0">{age}</h4>
                                  </div>
                                  <div class="incom-box">
                                      <p class="mb-0">Total Income</p>
                                      <p class="mb-0">{income}/annual</p>
                                  </div>
                                  <div class="expenses-box">
                                      <p class="mb-0">Total Expenses</p>
                                      <p class="mb-0">{expense}/annual</p>
                                  </div>
                              </div>`;

      // Create series
      // this.createSeries("income", "Income", "#2680E3");
      // this.createSeries("expense", "Expenses, Goals & Challenges", "#668F80");


      this.chart.legend = new am4charts.Legend();
      this.chart.legend.position = "top";
      this.chart.legend.contentAlign = "left";
      this.chart.legend.height = 70;
      this.chart.legend.fontSize = 12;
      this.chart.legend.fontWeight = 600;
      this.chart.legend.labels.template.fill = am4core.color("#717171");


      var marker = this.chart.legend.markers.template.children.getIndex(0);

      marker.strokeWidth = 0;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#ccc");
     
      // this.chart.scrollbarX = new am4charts.XYChartScrollbar();
      // this.chart.scrollbarX.series.push(series);

      // var scrollbarX = new am4charts.XYChartScrollbar();
      // scrollbarX.series.push(lineSeries2);
      // this.chart.scrollbarX = scrollbarX;
      // this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;



      // Add scrollbar
      this.chart.scrollbarX = new am4core.Scrollbar();
      this.chart.scrollbarX.startGrip.background.fill = am4core.color("#FD7A03");
      this.chart.scrollbarX.endGrip.background.fill = am4core.color("#FD7A03");
      this.chart.scrollbarX.thumb.background.fill = am4core.color("#DEE5EC");
      this.chart.scrollbarX.startGrip.icon.stroke = am4core.color("#ffa552");
      this.chart.scrollbarX.endGrip.icon.stroke = am4core.color("#ffa552");
      this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;
      // Applied on hover
      this.chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#FD7A03");
      this.chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#FD7A03");
      this.chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#DEE5EC");
      // Applied on mouse down
      this.chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("#FD7A03");
      this.chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("#FD7A03");
      this.chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("#DEE5EC");



      this.chart.responsive.useDefault = false;
      this.chart.responsive.enabled = true;

      // this.chart.responsive.rules.push({
      //     relevant: function(target) {
      //         if (target.pixelWidth <= 1024) {
      //             return true;
      //         }
      //         return false;
      //     },
      // });

      this.chart.cursor = new am4charts.XYCursor();
      this.chart.cursor.fullWidthLineX = true;
      this.chart.cursor.xAxis = categoryAxis;
      this.chart.cursor.lineY.strokeWidth = 0;
      this.chart.cursor.lineX.strokeWidth = 0;
      this.chart.cursor.lineX.fill = am4core.color("#000");
      this.chart.cursor.lineX.fillOpacity = 0.1;

    }); // end am4core.ready()
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style>
</style>
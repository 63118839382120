export default {
	marital_status: {
		id: "marital_status",
		label: "Status",
		options: ["Single", "Married"]
	},
	age: {
		id: "age",
		label: "Current Age",
		min: 50,
		max: 100,
		options: [
			...Array.from(Array(80).keys())
				.map(idx => idx + 21)
				.map(num => ({ id: num, text: `${num}` }))
		] // 1-100
	},
	state: {
		id: "state",
		label: "State of Primary Residence",
		min: 50,
		max: 100,
		options: [
			...Array.from(Array(50).keys())
				.map(idx => idx + 50)
				.map(num => ({ id: num, text: `${num}` }))
		] // 50-100
	},
	tabs: {
		Income: {
			detail: "Select Your annual Income",
			tabs: {
				Salary: [
					{
						id: 'inc_sal_0',
						image: "/images/covered.svg",
						icon: "fas fa-envelope-open-dollar",
						title: "Annual Salary 1",
						summary: "Total annual take-home amount.",
						description: "Annual Salary 1",
						annual_cost: "",
						age_min: 'current',
						age_max: 70,
						amount: 5000
					},
					{
						id: 'inc_sal_1',
						image: "/images/covered.svg",
						icon: "fas fa-envelope-open-dollar",
						title: "Annual Salary 2",
						summary: "Total annual take-home amount.",
						description: "Annual Salary 2",
						annual_cost: "",
						age_min: 'current',
						age_max: 70,
						amount: 5000
					},
					{
						id: 'inc_sal_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Fixed: [
					{
						id: 'inc_fix_0',
						image: "/images/covered.svg",
						icon: "fas fa-shield",
						title: "Social Security 1",
						summary: "Total annual take-home amount.",
						description: "Social Security 1",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_fix_1',
						image: "/images/covered.svg",
						icon: "fas fa-envelope-open-dollar",
						title: "Social Security 2",
						summary: "Total annual take-home amount.",
						description: "Social Security 2",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_fix_2',
						image: "/images/covered.svg",
						icon: "fas fa-file-invoice-dollar",
						title: "Pension 1",
						summary: "Total annual take-home amount.",
						description: "Pension 1",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_fix_3',
						image: "/images/covered.svg",
						icon: "fas fa-file-invoice-dollar",
						title: "Pension 2",
						summary: "Total annual take-home amount.",
						description: "Pension 2",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_fix_4',
						image: "/images/covered.svg",
						icon: "fas fa-sack-dollar",
						title: "Annuity 1",
						summary: "Total annual take-home amount.",
						description: "Annuity 1",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_fix_5',
						image: "/images/covered.svg",
						icon: "fas fa-sack-dollar",
						title: "Annuity 2",
						summary: "Total annual take-home amount.",
						description: "Annuity 2",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_fix_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Other: [
					{
						id: 'inc_oth_0',
						image: "/images/covered.svg",
						icon: "fas fa-child",
						title: "Alimony/Child Support",
						summary: "Total annual take-home amount.",
						description: "Alimony/Child Support",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_oth_1',
						image: "/images/covered.svg",
						icon: "fad fa-digging",
						title: "Structured Settlement",
						summary: "Total annual take-home amount.",
						description: "Structured Settlement",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_oth_2',
						image: "/images/covered.svg",
						icon: "fas fa-store-alt",
						title: "Property/Rental Income",
						summary: "Total annual take-home amount.",
						description: "Property/Rental Income",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'inc_oth_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				]
			}
		},
		Expenses: {
			detail: "Select Your annual Expenses",
			tabs: {
				Housing: [
					{
						id: 'exp_hou_0',
						image: "/images/covered.svg",
						icon: "fas fa-money-bill-wave",
						title: "Mortgage Payment",
						summary: "Annual amount, including principal and interest.",
						description: "Mortgage Payment",
						annual_cost: "",
						age_min: 'current',
						age_max: 85,
						amount: 5000
					},
					{
						id: 'exp_hou_1',
						image: "/images/covered.svg",
						icon: "fas fa-file-invoice-dollar",
						title: "Real Estate Taxes",
						summary: "Annual amount.",
						description: "Real Estate Taxes",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hou_2',
						image: "/images/covered.svg",
						icon: "fas fa-file-signature",
						title: "Homeowners or Rental Insurance",
						summary: "Annual amount.",
						description: "Homeowners or Rental Insurance",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hou_3',
						image: "/images/covered.svg",
						icon: "fas fa-hand-holding-usd",
						title: "Rent Payment",
						summary: "Annual amount.",
						description: "Rent Payment",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hou_4',
						image: "/images/covered.svg",
						icon: "fas fa-user-hard-hat",
						title: "Home Maintenance and Upkeep",
						summary: "The annual amount you'd like to save for home repairs. If there are large items upcoming, such as a roof or HVAC, you can include them by splitting the cost over multiple years of savings. Example: Roof- $20k=$5k for 4 years.",
						description: "Home Maintenance and Upkeep",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hou_5',
						image: "/images/covered.svg",
						icon: "fas fa-file-invoice",
						title: "Utilities",
						summary: "Annual amount, including items like gas, electricity, water, sewer, and garbage.",
						description: "Utilities",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hou_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Add other housing related expenses here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Personal: [
					{
						id: 'exp_per_0',
						image: "/images/covered.svg",
						icon: "fas fa-tty",
						title: "Communications",
						summary: "Annual amount, including phone and internet.",
						description: "Communications",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_per_1',
						image: "/images/covered.svg",
						icon: "fas fa-apple-crate",
						title: "Food",
						summary: "Groceries and dining out, annually.",
						description: "Food",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_per_2',
						image: "/images/covered.svg",
						icon: "far fa-tv-music",
						title: "Entertainment",
						summary: "Television subscriptions, movies, museums, and other local entertainment, annually.",
						description: "Entertainment",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_per_3',
						image: "/images/covered.svg",
						icon: "fas fa-tshirt",
						title: "Personal Items",
						summary: "Annual cost for clothing, glasses, hair care, etc.",
						description: "Entertainment",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_per_4',
						image: "/images/covered.svg",
						icon: "fas fa-golf-club",
						title: "Personal Memberships",
						summary: "Annual cost of boating, golf, sports, and other hobbies.",
						description: "Entertainment",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_per_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Transportation: [
					{
						id: 'exp_tra_0',
						image: "/images/covered.svg",
						icon: "fas fa-car",
						title: "Vehicle Loan",
						summary: "Annual payment total. If you have more than one loan, use the \"Add an Item\" box.",
						description: "Vehicle Loan",
						annual_cost: "",
						age_min: 'current',
						age_max: '+5',
						amount: 5000
					},
					{
						id: 'exp_tra_1',
						image: "/images/covered.svg",
						icon: "fas fa-car-side",
						title: "Saving for Vehicle Purchase",
						summary: "Many people save, then buy a vehicle in cash. Allocate annual savings for this here.",
						description: "Saving for Vehicle Purchase",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_tra_2',
						image: "/images/covered.svg",
						icon: "fas fa-gas-pump",
						title: "Fuel",
						summary: "Annual amount.",
						description: "Fuel",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_tra_3',
						image: "/images/covered.svg",
						icon: "fas fa-car-crash",
						title: "Auto Insurance",
						summary: "Total annual amount.",
						description: "Auto Insurance",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_tra_4',
						image: "/images/covered.svg",
						icon: "fad fa-car-mechanic",
						title: "Auto Repairs",
						summary: "Annual budget amount.",
						description: "Auto Repairs",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_tra_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Health_Care: [
					{
						id: 'exp_hea_0',
						image: "/images/covered.svg",
						icon: "fas fa-file-medical-alt",
						title: "Health Insurance",
						summary: "Health insurance, not including Medicare or Medicare supplements.",
						description: "Health Insurance",
						annual_cost: "",
						age_min: 'current',
						age_max: '+5',
						amount: 5000
					},
					{
						id: 'exp_hea_1',
						image: "/images/covered.svg",
						icon: "fas fa-notes-medical",
						title: "Medicare Supplements",
						summary: "Annual amount.",
						description: "Medicare Supplements",
						annual_cost: "",
						age_min: 65,
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hea_1',
						image: "/images/covered.svg",
						icon: "fas fa-file-medical",
						title: "Prescriptions",
						summary: "Annual amount including co-pays and out-of-pocket costs.",
						description: "Prescriptions",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hea_2',
						image: "/images/covered.svg",
						icon: "fas fa-files-medical",
						title: "Long-Term Care Policy Premium",
						summary: "Annual amount.",
						description: "Long-Term Care Policy Premium",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_hea_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Other: [
					{
						id: 'exp_oth_0',
						image: "/images/covered.svg",
						icon: "fas fa-credit-card",
						title: "Credit Card Balances",
						summary: "Total annual payments on credit card balances.",
						description: "Credit Card Balances",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'exp_oth_1',
						image: "/images/covered.svg",
						icon: "fas fa-hands-heart",
						title: "Charitable Giving",
						summary: "Annual amount.",
						description: "Charitable Giving",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_oth_2',
						image: "/images/covered.svg",
						icon: "fad fa-island-tropical",
						title: "Recurring Vacation Expenses",
						summary: "Total annual amount for recurring vacation-related expenses, such as timeshare payments, vacation club fees, etc.",
						description: "Recurring Vacation Expenses",
						annual_cost: "",
						age_min: 'current',
						age_max: 85,
						amount: 5000
					},
					{
						id: 'exp_oth_3',
						image: "/images/covered.svg",
						icon: "fas fa-wallet",
						title: "Other Recurring Expenses",
						summary: "Add any other expenses not covered in other sections.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'exp_oth_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				]
			}
		},
		Goals: {
			detail: "Select Your annual Goals",
			tabs: {
				Home: [
					{
						id: 'goa_hom_0',
						image: "/images/covered.svg",
						icon: "fas fa-paint-roller",
						title: "Home Upgrades",
						summary: "I'd like to improve my home, including rennovations, installing a pool, etc.",
						description: "Large items can be added by breaking them down into annual expenses and saving for them over time.",
						annual_cost: "",
						age_min: 'current',
						age_max: '+20',
						amount: 5000
					},
					{
						id: 'goa_hom_1',
						image: "/images/covered.svg",
						icon: "fas fa-sign",
						title: "Vacation Property Purchase",
						summary: "Add a home, vacation, or timeshare.",
						description: "Large items can be added by breaking them down into annual expenses and saving for them over time.",
						annual_cost: "",
						age_min: 'current',
						age_max: '+20',
						amount: 5000
					},
					{
						id: 'goa_hom_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Add other home related income.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: '+20',
						amount: 5000
					}
				],
				Family: [
					{
						id: 'goa_fam_0',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-edit-alt",
						title: "Leaving Money to Heirs",
						summary: "I want my retirement plan to include leaving money for kids, grandkids, and/or charities.",
						description: "This amount can be included in your income so you can save towards it or pay for life insurance, or it can be left out of your income plan to leave assets to heirs. Talk to your Heyday Consultant about your prefered method.",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'goa_fam_1',
						image: "/images/covered.svg",
						icon: "fas fa-gift",
						title: "Gift Budget",
						summary: "Add fixed income to budget for family gifts.",
						description: "Gift Budget",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_fam_2',
						image: "/images/covered.svg",
						icon: "fas fa-graduation-cap",
						title: "College Funding",
						summary: "Help your children/grandchildren pay for education.",
						description: "College Funding",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_fam_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Add income to acheive other family-related goals.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Travel: [
					{
						id: 'goa_tra_0',
						image: "/images/covered.svg",
						icon: "fas fa-plane",
						title: "Planned Travel",
						summary: "Add income to acheive pre-planned travel goals.",
						description: "Planned Travel",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_tra_1',
						image: "/images/covered.svg",
						icon: "fas fa-suitcase-rolling",
						title: "Additional Travel Budget ",
						summary: "Give yourself a buffer in your budget in case last-minute travel opportunities arise.",
						description: "Additional Travel Budget",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_tra_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Add other travel related income.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					}
				],
				Personal: [
					{
						id: 'goa_per_0',
						image: "/images/covered.svg",
						icon: "fas fa-rv",
						title: "Recreational Purchase",
						summary: "Buy a boat, RV, or other large recreational item.",
						description: "Large items can be added by breaking them down into annual expenses and saving for them over time.",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_per_1',
						image: "/images/covered.svg",
						icon: "far fa-id-card",
						title: "Additional Personal Budget ",
						summary: "Give yourself a buffer in your budget for new personal goal opportunities.",
						description: "Additional Personal Budget",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_per_2',
						image: "/images/covered.svg",
						icon: "fas fa-hands-usd",
						title: "Increased Charitable Giving ",
						summary: "Add to your charitable giving through increased income.",
						description: "Increased Charitable Giving",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					},
					{
						id: 'goa_per_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Add other personal budget items.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: '+10',
						amount: 5000
					}
				]
			}
		},
		Challenges: {
			detail: "Select Your annual Goals",
			tabs: {
				Transition: [
					{
						id: 'cha_tra_0',
						image: "/images/covered.svg",
						icon: "fas fa-chart-line-down",
						title: "Early Retirement Income Shortfall",
						summary: "I'm concerned about the sizable income drop-off at retirement.",
						description: "Additional income from retirement savings could be used here.",
						annual_cost: "",
						age_min: 'current',
						age_max: 67,
						amount: 5000
					},
					{
						id: 'cha_tra_1',
						image: "/images/covered.svg",
						icon: "fas fa-comments-dollar",
						title: "Dual Salary Dependence",
						summary: "One spouse is still working, which could hinder our plans for early retirement.",
						description: "Additional income from retirement savings could be used here.",
						annual_cost: "",
						age_min: 'current',
						age_max: 67,
						amount: 5000
					},
					{
						id: 'cha_tra_2',
						image: "/images/covered.svg",
						icon: "fas fa-plane-slash",
						title: "Cancelled Plans",
						summary: "I'm worried about having to put off plans, such as travel, to make sure I'm not overspending.",
						description: "Additional income from retirement savings could be used here.",
						annual_cost: "",
						age_min: 'current',
						age_max: 70,
						amount: 5000
					},
					{
						id: 'cha_tra_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 70,
						amount: 5000
					}
				],
				Economy: [
					{
						id: 'cha_eco_0',
						image: "/images/covered.svg",
						icon: "fas fa-balance-scale-right",
						title: "Economic Uncertainty",
						summary: "I need to plan for potenial economic changes, such as tax increases or market volatility.",
						description: "Covering fixed expenses and goals and challenges with fixed income (not tied to economic changes) can alleviate this challenge.",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'cha_eco_1',
						image: "/images/covered.svg",
						icon: "fas fa-coins",
						title: "Running Out of Money",
						summary: "With so many unknowns, I find myself worried that I may run out of money later.",
						description: "A certain amount of your income can be in financial vehicles that are guranteed for life, such as social security, pensions, and annuities.",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'cha_eco_2',
						image: "/images/covered.svg",
						icon: "fas fa-chart-pie",
						title: "Inflation (Rising Expenses)",
						summary: "I may have enough for today, but I'm concerned how inflation will impact my spending power later on.",
						description: "Heyday automatically calculates inflation into certain expense categories. Because we do it automatically, there is no need for you to manually inflate your budget in Heyday. We determine the inflation rate based on standards such as the Consumer Price Index (CPI) and historical averages. If you would like to adjust this rate up or down, you are able to do so below.",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'cha_eco_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Any additional costs can be included here.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Uncertainty: [
					{
						id: 'cha_unc_0',
						image: "/images/covered.svg",
						icon: "fas fa-traffic-light",
						title: "Emergency Fund for Unknown Expenses",
						summary: "I'd like to make sure I maintain an emergency fund, and possibly continue adding to it.",
						description: "Continuing to save into an emergency fund in retirement can help you feel comfortable spending, knowing ememrgencies are covered.",
						annual_cost: 2000,
						age_min: 'current',
						age_max: 100,
						amount: 5000
					},
					{
						id: 'cha_unc_1',
						image: "/images/covered.svg",
						icon: "fas fa-user-friends",
						title: "Helping Kids and Grandkids",
						summary: "I'm concerned that I may need to help support my Adult child or grandchildren",
						description: "Caring for adult children & grandchildren can be an unexpected retirement expense, which can place retirement retirement plans in jeopardy.",
						annual_cost: 2000,
						age_min: 72,
						age_max: 75,
						amount: 5000
					},
					{
						id: 'cha_unc_2',
						image: "/images/covered.svg",
						icon: "fas fa-blind",
						title: "Caring for Aging Parents ",
						summary: "I have a parent/parents that may need assistance, requiring an additional time or financial comittment.",
						description: "Caring for adult children & grandchildren can be an unexpected retirement expense, which can place retirement retirement plans in jeopardy.",
						annual_cost: 5000,
						age_min: 75,
						age_max: 78,
						amount: 5000
					},
					{
						id: 'cha_unc_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Any additional concerns can be added here to discuss with your Heyday Consultant.",
						description: "",
						annual_cost: 5000,
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Healthcare: [
					{
						id: 'cha_hea_0',
						image: "/images/covered.svg",
						icon: "fas fa-heartbeat",
						title: "Healthcare Costs Up to Age 65",
						summary: "I'm concerned about how I should plan for helathcare costs before I reach age 65.",
						description: "Retiring early can present some unique healthcare challenges. Before age 65, we need careful planning top avoid the potential of extremely high healthcare costs.",
						annual_cost: 5000,
						age_min: 'current',
						age_max: 65,
						amount: 5000
					},
					{
						id: 'cha_hea_1',
						image: "/images/covered.svg",
						icon: "fas fa-bed",
						title: "Healthcare Costs After Age 65",
						summary: "After age 65, I want to be prepared for healthcare costs not covered by Medicare.",
						description: "While Medicare premiums should be manageable, medical emergencies can carry out of pocket maximums as high as $7,000-$10,000.",
						annual_cost: "",
						age_min: 66,
						age_max: 100,
						amount: 5000
					},
					{
						id: 'cha_hea_2',
						image: "/images/covered.svg",
						icon: "fas fa-tooth",
						title: "Uncovered Prescription Costs",
						summary: "I want to make sure I have enough to afford any needed prescritions that aren't covered by Medicare.",
						description: "A complete retirement plan may need to include setting money aside for uncovered prescriptions. When the prescriptions we need aren't covered, the costs can wear down our savings.",
						annual_cost: 1200,
						age_min: 65,
						age_max: 100,
						amount: 5000
					},
					{
						id: 'cha_hea_3',
						image: "/images/covered.svg",
						icon: "fas fa-money-bill-wave",
						title: "Dental Care",
						summary: "I may need to prepare for potentially large dental care costs.",
						description: "Dental care costs are rapidly increasing, and this is one of the most common unplanned expenses in retirement. This can potentially decrease your savings by tens of thousands of dollars.",
						annual_cost: 10000,
						age_min: 80,
						age_max: 80,
						amount: 5000
					},
					{
						id: 'cha_hea_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Any additional Healthcare concerns can be added here to discuss with your Heyday Consultant.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				],
				Aging: [
					{
						id: 'cha_agi_0',
						image: "/images/covered.svg",
						icon: "fas fa-brush",
						title: "Home Maintenance and Repairs",
						summary: "I anticipate some large home maintenance costs at some point in retirement.",
						description: "Unfortunately, our homes age and need constant maintenance. New roofs, air-conditioners, and renovations are just a few of the larger lump sum costs that should be considered during retirement.",
						annual_cost: 5000,
						age_min: 78,
						age_max: 82,
						amount: 5000
					},
					{
						id: 'cha_agi_1',
						image: "/images/covered.svg",
						icon: "fas fa-tombstone",
						title: "Loss of a Spouse",
						summary: "I want to have a financial plan in place in case a spouse passes.",
						description: "Unfortunately, losing a spouse is likely to result in an income loss. Plans should be made for decreases in social security payments, pensions, and other income sources.",
						annual_cost: "",
						age_min: '',
						age_max: '',
						amount: 5000
					},
					{
						id: 'cha_agi_2',
						image: "/images/covered.svg",
						icon: "fas fa-house-user",
						title: "Aging at Home",
						summary: "Besides healthcare, I want to plan for other costs associated with aging in place.",
						description: "It's hard to fathom having to leave the security of our homes in our later years. The costs asccoiated with preparing our home for aging in place can be a higher than expected.",
						annual_cost: 7500,
						age_min: 85,
						age_max: 85,
						amount: 5000
					},
					{
						id: 'cha_agi_3',
						image: "/images/covered.svg",
						icon: "fas fa-clinic-medical",
						title: "Home Healthcare (Unskilled)",
						summary: "I plan on staying home as long as possible, with unskilled healthcare help.",
						description: "Aging at home can be possible up to a point. Planning for this unskilled help should include costs in the range of $3,000/month.",
						annual_cost: 36000,
						age_min: 87,
						age_max: 90,
						amount: 5000
					},
					{
						id: 'cha_agi_4',
						image: "/images/covered.svg",
						icon: "fas fa-house-user",
						title: "Long-Term Care",
						summary: "I would like to look at Long-Term Care options.",
						description: "Long-Term Care prices have, historically, steadily increased. We should assume this trend will continue. Current national averages are approximately $7,000 per month.",
						annual_cost: 84000,
						age_min: 90,
						age_max: 95,
						amount: 5000
					},
					{
						id: 'cha_agi_new',
						image: "/images/covered.svg",
						icon: "fas fa-money-check-alt",
						title: "Add an Item",
						summary: "Any additional aging concerns can be added here to discuss with your Heyday Consultant.",
						description: "",
						annual_cost: "",
						age_min: 'current',
						age_max: 100,
						amount: 5000
					}
				]
			}
		}
	}
};

<template>
  <div>
  <div class="page_content_wrap" v-if="!isLoading">
    <div class="add_goals_section">
        <div class="add_goals_sidebar">
          <profile-nav />
          <div class="add_goals_sidebar_form">
            
            <div class="tab_section">
              <nav>
                <div
                  class="nav nav-tabs nav-fill parent-tab"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    v-for="(value, key) in CalculatorData.tabs"
                    :key="key"
                    :class="{
                      active: key == activeTab,
                      'first-box': activeTab == 'Income',
                    }"
                    @click="changeMainTab(key)"
                    class="nav-item nav-link rounded-0"
                    :id="'nav-' + key + '-tab'"
                    data-toggle="tab"
                    :href="'#nav-' + key"
                    role="tab"
                    :aria-controls="'nav-' + key"
                    :aria-selected="key == activeTab"
                    >{{ key }}</a
                  >
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade"
                  v-for="(value, key) in CalculatorData.tabs"
                  :key="key"
                  :class="{
                    'active show': key == activeTab,
                    first: activeTab == 'Income',
                  }"
                  :id="'nav-' + key"
                  role="tabpanel"
                  :aria-labelledby="'nav-' + key + '-tab'"
                >
                  <div class="inner-tab">
                    <div class="inner-head">
                        <p class="text-dark font-weight-bolder font-size-sm m-0">
                          {{ value.detail }}
                        </p>
                    </div>
                    
                    <div class="tab_section">
                      <nav>
                        <div
                          class="nav nav-tabs nav-fill child-tab"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            class="nav-item nav-link first-tab"
                            v-for="(value2, key2) in value.tabs"
                            :key="key2"
                            @click="activeSubTab = key2"
                            :class="{ active: key2 == activeSubTab }"
                            :id="'nav-' + key2 + '-tab'"
                            data-toggle="tab"
                            :href="'#nav-' + key2"
                            role="tab"
                            :aria-controls="'nav-' + key2"
                            :aria-selected="key2 == activeSubTab"
                            >{{ camelCase(key2) }}</a
                          >
                        </div>
                      </nav>
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          v-for="(value2, key2) in value.tabs"
                          :key="key2"
                          class="tab-pane fade"
                          :class="{ 'active show': key2 == activeSubTab }"
                          :id="'nav-' + key2"
                          role="tabpanel"
                          :aria-labelledby="'nav-' + key2 + '-tab'"
                        >
                          <div class="form-group">
                            <div
                              class="tab_btn_with_txt btn-group-toggle option_type"
                              data-toggle="buttons"
                            >
                              <div class="row">
                                <template v-for="(field, index) in value2">
                                <div
                                  class="col-6 col-sm-4 p-1"
                                   :class="{ 
                                      'disabled' : itemNotInRange(field.age_min, field.age_max)
                                    }"
                                  :key="index"
                                  @click.prevent="openItemModal(field, itemNotInRange(field.age_min, field.age_max))"
                                >    
                                  <label
                                    class="btn btn-outline-primary shadow-none bounce"
                                    :class="{ 
                                      'active' : selection[field.id] ? true : false
                                    }"
                                  >
                                    <!-- <input
                                      type="checkbox"
                                      :value="field.id"
                                      :v-model="selection[field.id] ? true : false"
                                      
                                    /> -->
                                    <div class="check">
                                      <i class="fas fa-check"></i>
                                    </div>
                                    <i :class="field.icon"></i>
                                    <p class="mb-0">{{ field.title }}</p>

                                    <span class="font-size-sm"
                                      >${{ getBoxSelectedAmmount(field) }}/annual</span
                                    >
                                  </label>
                                </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <modal
              :activeModal="activeModal"
              :activeTab="activeTab"
              :ageOption="ageOption"
            />

            <selection />
          </div>
        </div>
        <div class="add_goals_content">
          <chart />
        </div>
    </div>
  </div>
  <div v-else class="loader-overlay"><div class="loader"></div></div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, calculatorData } from "@/utils/firebase";
import QUESTIONS from "@/data/personal-details-questions";
import CalculatorData from "@/data/calculator";
import ProfileNav from "./ProfileNav";
import Chart from "./Chart";
import Modal from "./Modal";
import Selection from "./Selection";
import { startCase as _startCase, isEmpty, isInteger } from 'lodash';
import { CALC_LIST_KEY } from '@/utils/constants'

const ageDefault = {
  range: [0, 100],
  min: 0,
  max: 100,
};

export default {
  name: "Calculator",
  components: {
    ProfileNav,
    Chart,
    Modal,
    Selection
  },
  data() {
    return {
      activeModal: null,
      activeTab: "Income",
      activeSubTab: "Salary",
      CalculatorData,
      questions: QUESTIONS,
      isLoading: true,
      ageOption: ageDefault
    };
  },
  computed: {
		...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId",
    }),
    ...mapGetters("wizard", {
      progress: "getProgress",
      survey: "getSurvey",
    }),
    ...mapGetters("calculator", {
      selection: "getSelection"
    }),
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapActions("calculator", {
      removeItem: "removeItem",
    }),
    ...mapMutations("wizard", ["SET_SURVEY", "UPDATE_SURVEY_FIELD"]),
    ...mapMutations("calculator", ["SET_SELECTION"]),
    camelCase(str) {
      let newStr = str.replace("_", " ");
      return _startCase(newStr);
    },
		getBoxSelectedAmmount(item) {
			if(this.selection[item.id]) {
				return this.selection[item.id].amount;
			} else {
				return 0;
			}
		},
    changeMainTab(key) {
      this.activeTab = key;
      this.activeSubTab = Object.keys(CalculatorData.tabs[key].tabs)[0];
    },
    openItemModal(data, notInRange) {

        if(notInRange) return; 

        this.activeModal = data;

        let min = data.age_min == "current" ? this.getUser.age : data.age_min;
        min = parseInt(min);
        let max = data.age_max;
        
        if(typeof max == 'string' && max.includes('+')){
          max = min + parseInt(max);
        }

        //if provided range is blow current age. assign current as min selection option.
        if(min < this.getUser.age) {
          min = this.getUser.age;
        }
        this.ageOption.min = min;
        this.ageOption.max = max;
        this.ageOption.range = [min, max];

        Fire.$emit("openModal", data);
    },
    itemNotInRange(age_min, age_max) {
      if(!isInteger(age_max)) {
        return true;
      } else {
        return isInteger(age_max) && this.getUser.age > age_max
      }
      
    }
  },
  created() {
    this.formatter = (value) => `$${value}`;
  },
  mounted() {

    Fire.$on("closeModal", () => {
      this.activeModal = null;
      this.ageOption = ageDefault;
    });

    // var selection = localStorage.getItem(CALC_LIST_KEY);
    // if(!isEmpty(selection)) {
    //   this.SET_SELECTION(JSON.parse(selection));
    // }

    // console.log('localStorage selection');
    // console.log(JSON.parse(selection));
    // console.log('calculatorData');
    // console.log(this.getUserId);

    calculatorData.doc(this.getUserId).get().then(document => {
        console.log('calculatorData exists =>' + document.exists);
        //console.log(document.data());
        let doc = null;
        if (document && !document.exists) {
            calculatorData.doc(this.getUserId).set({selection: {}, created: new Date().toISOString()}, { merge: true });
        } else {
            doc = document.data();
            this.SET_SELECTION(doc.selection);
        }
    });

    this.isLoading = true;
    // const contactId = Object.keys(QUESTIONS)
    const contactId = "kyle@dataskeptic.com"
    getContact(contactId)
      .then((response) => {
        let prop = response.data.properties;
        if (prop && typeof prop.age != undefined && prop.age == "") {
          this.$router.replace({ name: 'risk'});
        } else {
          Fire.$emit("contactDetail", prop);
          this.SET_SURVEY(prop);
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        console.log("error");
        Fire.$emit("contactDetail", null);
      });
  },
};
</script>

<style>
</style>
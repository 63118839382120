<template>
  <div class="my_selection_wrap">
    <div class="my_selection_content" v-if="selectionModalData">
      <div class="my_selection_content_head">
        <h5 class="m-0">Selection Results</h5>
        <p class="m-0">
          Your selected Income, Expenses, Goals &amp; Challenges.
        </p>
        <a
          class="close"
          href="javascript:void(0);"
          @click.prevent="closeSideBar"
          ><i class="fal fa-times"></i
        ></a>
      </div>

      <div
        class="my_selection_result"
        v-for="(item, index) in selectionModalData"
        :key="index"
      >
        <h6 class="m-0 text-primary">{{ index }}</h6>
        <div class="my_selection_result_table" v-if="item.category.length">
          <table class="table">
            <thead>
              <tr>
                <th width="50%">Name</th>
                <th width="20%" class="text-center">Age range</th>
                <th width="20%" class="text-center">Per Year</th>
                <th width="10%" class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(local, inx) in item.category" :key="inx">
                <td>
                  <i class="mr-1" :class="local.icon"></i> {{ local.title }}
                </td>
                <td class="text-center">{{ local.range }}</td>
                <td class="text-center">${{ local.amount }}</td>
                <td class="text-center">
                  <button
                    class="btn text-danger"
                    :class="{ 'btn-load': local.id == deleteId }"
                    @click="removeData(local, index, inx)"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else role="alert" class="alert alert-danger mt-2">
          There are no <strong>{{ index }}</strong> Selected
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CalculatorData from "@/data/calculator";
export default {
  name: "Selection",
  data() {
    return {
      selectionModalLoading: false,
      selectionModalData: null,
      deleteId: null,
      need: 0,
      filterdList: {},
      CalculatorData,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId",
    }),
    ...mapGetters("calculator", {
      selection: "getSelection",
    }),
  },
  methods: {
    ...mapActions("calculator", {
      removeItem: "removeItem",
    }),
    getModalData(callback) {
      let keys = Object.keys(this.selection);
      let result = {
        Income: {
          category: [],
          total: 0,
        },
        Expenses: {
          category: [],
          total: 0,
        },
        Goals: {
          category: [],
          total: 0,
        },
        Challenges: {
          category: [],
          total: 0,
        },
      };
      let filterdList = this.filterdList;
      let totalIncome = 0;
      let totalExpenses = 0;
      keys.forEach((key, index) => {
        let range = this.selection[key].range;
        let min = range[0];
        let max = range[1];
        let amount = this.selection[key].amount;
        // console.log("key => " + key);
        // console.log("range => " + range);
        // console.log("min => " + min);
        // console.log("max => " + max);
        // console.log("amount => " + amount);

        // console.log(key);
        // console.log(filterdList[key]);

        let gparent = filterdList[key].gparent;
        if (gparent == "Income") {
          totalIncome += amount;
        } else {
          totalExpenses += amount;
        }
        result[filterdList[key].gparent].category.push({
          icon: filterdList[key].icon,
          title: filterdList[key].title,
          amount: amount,
          range: `${min} - ${max}`,
          id: key,
        });
        result[filterdList[key].gparent].total += amount;

        if (keys.length - 1 == index) {
          console.log(totalExpenses);
          console.log(totalIncome);
          let need = totalIncome - totalExpenses;
          callback && callback(need, result);
        }
      });
    },
    openAgeDataModal() {
      this.selectionModalLoading = true;

      this.getModalData((need, result) => {
        console.log("result");
        console.log(result);
        this.selectionModalData = result;
        this.selectionModalLoading = false;
      });
    },
    closeSideBar() {
      $("body").removeClass("open_selection");
    },
    removeData(item, label, index) {
      console.log(item);
      console.log(this.selectionModalData[label]);

      this.deleteId = item.id;

      console.log({ uid: this.getUserId, item_id: item.id });

      this.removeItem({ uid: this.getUserId, item_id: item.id });
      setTimeout(() => {
        Fire.$emit("setChartData");
        this.selectionModalData[label].category.splice(index, 1);
        this.deleteId = null;
      }, 1000);
    },
  },
  mounted() {
    Object.keys(CalculatorData.tabs).forEach((item) => {
      let localTabs = CalculatorData.tabs[item].tabs;
      // console.log(localTabs);
      Object.keys(localTabs).forEach((item2) => {
        // console.log(item2);
        let localTabs2 = localTabs[item2];
        // console.log(localTabs2);
        localTabs2.forEach((item3, index) => {
          this.filterdList[item3.id] = {
            icon: item3.icon,
            title: item3.title,
            parent: item2,
            gparent: item,
          };
        });
      });
    });
    $(".profile-box-section").on('click', '.open_selection_btn', () => {
      this.openAgeDataModal();
      $("body").addClass("open_selection");
    });
  },
};
</script>


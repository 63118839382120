<template>
  <div
    class="medical-modal shadow"
    :class="{ 'green': activeTab != 'Income' }"
    v-if="activeModal"
  >
    <div id="medical-modal" class="">
      <div class="popup">
        <a class="close" href="#" @click.prevent="closeModal"
          ><i class="fal fa-times"></i
        ></a>
        <div class="img-box text-center mb-3">
          <img :src="activeModal.image" alt="covered" class="w-80" />
        </div>
        <div class="icon-box">
          <h3 class="text-primary">
            <i class="pr-1" :class="activeModal.icon"></i>
            {{ activeModal.title }}
          </h3>
          <p>
            {{ activeModal.summary }}
          </p>
        </div>
        <div class="content">
          <div class="form-group range_slider_wrap mb-5">
            <label class="text-dark">Age range</label>

            <vue-range-slider
              :min="ageOption.min"
              :max="ageOption.max"
              v-model="ageOption.range"
              :bg-style="bgStyle" :tooltip-style="tooltipStyle" :process-style="processStyle"
              :dot-size="20"
              :height="8"
              :clickable="true"
              :tooltip-dir="['bottom', 'bottom', 'left', 'right']"
            ></vue-range-slider>

            <div class="content_bottom">
              <h6 class="m-0 d-inline">{{ ageOption.min }}</h6>
              <h6 class="m-0 float-right">{{ ageOption.max }}+</h6>
            </div>
          </div>
          <div class="form-group range_slider_wrap mb-5">

            <div class="row">
                <div class="col-6 col-sm-7">
                    <label class="text-dark">Per Year</label>
                </div>
                <div class="col-6 col-sm-5">
                    <input type="number" v-model="amount" class="form-control" />
                </div>
            </div>

            <vue-range-slider
              :min="minAmount"
              :max="maxAmount"
              v-model="amount"
              :formatter="formatter"
              :bg-style="bgStyle" :tooltip-style="tooltipStyle" :process-style="processStyle"
              :dot-size="20"
              :height="8"
              :clickable="true"
              :tooltip-dir="['top', 'bottom', 'left', 'right']"
            ></vue-range-slider>
            <div class="content_bottom">
              <h6 class="m-0 d-inline">${{ minAmount / 1000 }}k</h6>
              <h6 class="m-0 float-right">${{ maxAmount / 1000 }}k</h6>
            </div>
          </div>
          <div class="include_btn_box text-center">
            <button class="btn btn-light mr-1" @click="removeData()"  :class="{ 'btn-load': isLoadingClear}" :disabled="isLoadingClear">
              <i class="fal fa-trash-alt pr-1"></i> Clear
            </button>
            <button class="btn btn-primary" @click="addData()" :class="{ 'btn-load': isLoading}" :disabled="isLoading">
              <i class="far fa-check pr-1"></i> Include in Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.medical-modal.green .icon-box h3,
.medical-modal.green .icon-box i {
  color: #668f80 !important;
}
.vue-range-slider.slider-component {
  padding: 8px 0px !important;
  margin-bottom: 10px;
}
</style>

<style >


.vue-range-slider.slider-component .slider .slider-dot .slider-dot-handle {
  background: #f6bd60;
  border: 2px solid #fff!important;
}
</style>

<script>
import "vue-range-component/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component";
import { mapGetters, mapActions, mapMutations } from "vuex";
const defaultAmount = 5000;

export default {
  name: "Modal",
  props: {
    activeModal: {
      type: Object,
      default: null,
    },
    ageOption: {
      type: Object,
      default: null,
    },
    activeTab: {
      type: String,
      default: "",
    },
  },
  components: {
    VueRangeSlider,
  },
  data() {
    return {
      prop: null,
      isLoading: false,
      isLoadingClear: false,
      isSubmitting: false,
      minAmount: defaultAmount,
      maxAmount: 100000,
      amount: defaultAmount
    };
  },
  computed: {
    ...mapGetters("calculator", {
      getSelection: "getSelection",
    }),
    ...mapGetters({
      getUserId: "getUserId"
    }),
  },
  methods: {
    ...mapActions("calculator", {
      addItem: "addItem",
      removeItem: "removeItem",
    }),
    closeModal() {
      this.amount = defaultAmount;
      Fire.$emit("closeModal");
    },
    addData() {
      this.isLoading = true;
      let data = {
        id: this.activeModal.id,
        range : this.ageOption.range,
        amount: this.amount
      };
    
      this.addItem({uid: this.getUserId, data });

      setTimeout(() => {
        this.isLoading = false;
        Fire.$emit("setChartData");
        this.closeModal();
      }, 1000); 
    },
    removeData() {
      this.isLoadingClear = true;
      console.log({uid: this.getUserId, item_id: this.activeModal.id });
      this.removeItem( {uid: this.getUserId, item_id: this.activeModal.id })

      setTimeout(() => {
        this.isLoadingClear = false;
        Fire.$emit("setChartData");
        this.closeModal();
      }, 1000); 
      
    }
  },
  created() {

    this.bgStyle = {
     backgroundColor: '#dee5ec',
    }
    this.tooltipStyle = {
      backgroundColor: '#f3a930',
      borderColor: '#f3a930'
    }
    this.processStyle = {
      backgroundColor: '#f6bd60',
    }
   
    this.formatter = (value) => `$${value}`;
  },

  mounted() {
     Fire.$on("openModal", (activeModal) => {
      console.log('activeModal');
      console.log(activeModal);
      if(typeof this.getSelection[activeModal.id] != 'undefined') {
          this.ageOption.range = this.getSelection[activeModal.id].range;
          this.amount = this.getSelection[activeModal.id].amount;
      } else {
        this.amount = activeModal.amount;
      }
    });
  },
};
</script>

<style>
</style>
// ==== NOTE ====
// These values MUST match Hubspot internal options values
// Unless the field is not a select/checkbox
// Verify they still work after making any changes
// ==============

export default {
  marital_status: {
    id: 'marital_status',
    question: 'What’s your marital status?',
    options: ['Single', 'Married']
  },
  are_you_retired: {
    id: 'are_you_retired',
    question: 'Currently working or retired?',
    options: ['Working', 'Retired', 'Other']
  },
  age: {
    id: 'age',
    question: 'What is your age?',
    detail: '',
    min: 21,
    max: 100,
    options: [
      ...Array.from(Array(80).keys())
        .map(idx => idx + 21)
        .map(num => ({ id: num, text: `${num}` }))
    ] // 1-100
  },
  retirement_age: {
    id: 'retirement_age',
    question: 'At what age do you plan to retire?',
    detail: '',
    min: 50,
    max: 100,
    options: [
      ...Array.from(Array(50).keys())
        .map(idx => idx + 50)
        .map(num => ({ id: num, text: `${num}` }))
    ] // 50-100
  },
  my_top_retirement_concerns : {
    id: 'my_top_retirement_concerns',
    question: 'What would you say is your primary retirement concern?',
    options: [
      {
        icon: "fal fa-calendar-alt",
        option: 'Should I retire now or wait?',
      },
      {
        icon: "fal fa-coins",
        option: 'How much can I safely spend without running out of money?',
      },
      {
        icon: "fal fa-rings-wedding",
        option: "I'd like a plan in case a spouse passes.",
      },
      {
        icon: "fal fa-chart-line-down",
        option: "I'm concerned about volatile markets and/or the economy.",
      },
      {
        icon: "fal fa-medkit",
        option: 'I need to plan for healthcare costs.'
      } 
    ]
  },
  // industry: {
  //   id: 'industry',
  //   question: 'What industry do you work in?',
  //   options:
  //     // used 11 GICS sectors
  //     [
  //       'Energy',
  //       'Materials',
  //       'Industrial',
  //       'Consumer Discretionary',
  //       'Consumer Staples',
  //       'Health Care',
  //       'Financial',
  //       'Information Technology',
  //       'Telecommunication Services',
  //       'Utilities',
  //       'Real Estate',
  //       'Hospitality',
  //       'Travel/Tourism',
  //       'Other'
  //     ].map(option => ({ id: option, text: option }))
  // },
  // personal_finances_attitude: {
  //   id: 'personal_finances_attitude',
  //   question: 'How would you describe your attitude towards personal finances?',
  //   options: [
  //     'Very conservative',
  //     'Somewhat conservative',
  //     'Moderate',
  //     'Somewhat Aggressive',
  //     'Very Aggressive',
  //     'I’d rather not say'
  //   ]
  // }
}

<template>
  <div class="results-modal">
    <div class="modal-top" v-if="resultModalData">
      <h5>Results</h5>
      <p>Estimate your annual income need for the year.</p>
    </div>
    <div class="close-box">
      <a class="close" href="javascript:void(0);"
        ><i class="fal fa-times"></i
      ></a>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="age-box">
          <h6>At age:</h6>
          <p>
            <span class="text-primary mr-1">{{ age }}</span
            >Years
          </p>
        </div>
      </div>
      <div class="col-6">
        <div class="age-box text-right">
          <h6>Total income needed:</h6>
          <p><span class="text-primary mr-1">${{ need }}</span>/annual</p>
        </div>
      </div>
    </div>
    <div class="modal_content">
      <div class="row">
          
        <div class="col-sm-12 col-md-6" v-for="(item, index) in resultModalData" :key="index">
          <div class="expenses-box pb-4">
            <div class="expenses-top">
              <h6>{{ index }}</h6>
            </div>
            <div class="expenses-contant" v-if="item.category.length">
              <div class="contant-box d-flex py-2 p-2 border" v-for="(local, inx) in item.category" :key="inx">
                <p class="mb-0 w-50"><i class="mr-2" :class="local.icon"></i>{{ local.title }}</p>
                <p class="text-right mb-0 w-50">${{ local.amount }}/annual</p>
              </div>
              <div class="contant-box d-flex py-2 p-2 border contant-footer">
                <p class="mb-0 w-50">{{index}} total:</p>
                <p class="text-right mb-0 w-50">{{ item.total }}/annual</p>
              </div>
            </div>
            <div class="expenses-contant" v-else>
              <div class="alert alert-danger" role="alert">
                There are no <strong>{{ index }}</strong> Selected
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ResultModal",
  data() {
    return {
      resultModalData: null,
      age: 0,
      need: 0
    }
  },
  mounted() {
    Fire.$on("openModalResult", (data) => {
      console.log('openModalResult');
      console.log(data);
      this.need = data.need;
      this.age = data.age;
      this.resultModalData = data.result;
    });

    Fire.$on("closeModalResult", () => {
      this.need = 0;
      this.age = 0;
      this.resultModalData = null;
    });
  },
};
</script>
